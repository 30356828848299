import React, { useEffect, useState } from "react";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "./styles.css";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomInputFieldMultiline from "../input_field_multiline";
import CustomInputField from "../input_field";

function GenerateLink() {
  const API_URL = "https://handle-links-1049948018753.us-central1.run.app";
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const userEmail = localStorage.getItem("userEmail");

  const [inputSearchTerm, setInputSearchTerm] = useState("");
  const [inputRedirectUrl, setInputRedirectUrl] = useState("");

  const Tips = [
    "To create a new link, fill in the fields below:",
    "Search Term: Enter the search query you want to use. This is the text that users will search for.",
    "Redirect URL: Enter the URL where users will be redirected after performing the search.",
    "After filling in all fields, click the Generate Link button. The generated link will appear below, and you can copy it for further use.",
    "Copy link without quotes",
  ];

  const renderTips = (tipsList) => {
    return tipsList.map((item, key) => {
      const itemKey = `element-${key}`;

      return (
        <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
            {item}
          </MDTypography>
        </MDBox>
      );
    });
  };

  const handleGenerateLink = () => {
    if (!inputSearchTerm || !inputRedirectUrl) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    fetch(`${API_URL}/generate_link/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        search_term: inputSearchTerm,
        redirect_url: inputRedirectUrl,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("Link successfully generated!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleGenerateLink}>
            Generate Link💨
          </button>
        </div>
      </div>
    );
  };

  const ResponseMessage = () => {
    return (
      <div className="response-message-container">
        {isLoading ? (
          <div className="loading-spinner">
            <CircularProgress color="success" />
          </div>
        ) : (
          <Card className="response-message" sx={{ overflow: "auto" }}>
            <pre>{responseMessage}</pre>
          </Card>
        )}
      </div>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={3}>
                <MDBox p={0} mb={3} display="flex" alignItems="center">
                  <img
                    // src="https://www.svgrepo.com/show/477052/link.svg"
                    src="https://www.svgrepo.com/show/271745/monitor-link.svg"
                    alt="Red Arrow"
                    style={{ width: "40px", height: "40px" }}
                  />
                  <MDTypography variant="h5">&nbsp;&nbsp;URL Construction Form</MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                  mt={3}
                >
                  <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                    {renderTips(Tips)}
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        {/*<MDTypography variant="h6" color="text">*/}
                        {/*  Fb__link_for_fb*/}
                        {/*</MDTypography>*/}
                        <CustomInputField
                          inputValue={inputSearchTerm}
                          setInputValue={setInputSearchTerm}
                          label={"Search for ..."}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        {/*<MDTypography variant="h6" color="text">*/}
                        {/*  Fb__link_for_fb*/}
                        {/*</MDTypography>*/}
                        <CustomInputField
                          inputValue={inputRedirectUrl}
                          setInputValue={setInputRedirectUrl}
                          label={"https://..."}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </MDBox>
              <MDBox pt={2} px={2}>
                <ButtonList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default GenerateLink;
