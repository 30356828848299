import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "../../../examples/Tables/DataTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditDialog from "./campaigns_date_dialog";

const scrollStyle = {
  maxHeight: "200px",
  maxWidth: "200px",
  overflowY: "auto",
};

function CampaignsALL() {
  useEffect(() => {
    const element = document.querySelector(".MuiBox-root.css-fxid9l");
    if (element) {
      element.remove();
    }
  }, []);
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "Update_time", accessor: "update_time", width: "10%" },
      { Header: "Campaign ID", accessor: "camp_id", width: "10%" },
      {
        Header: "MediaBuyer Name",
        accessor: "campaign_name",
        width: "10%",
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => <div style={scrollStyle}>{value}</div>,
      },
      { Header: "Campaign ON/OFF", accessor: "campaignToggle", width: "10%" },
      { Header: "Spend", accessor: "Spend", width: "10%" },
      { Header: "Rev_a", accessor: "Revenue_a", width: "10%" },
      { Header: "Rev_f", accessor: "Revenue_f", width: "10%" },
      { Header: "ROI", accessor: "ROI", width: "10%" },
      { Header: "ROI_f", accessor: "ROI_f", width: "10%" },
      { Header: "ROI_latest", accessor: "ROI_latest", width: "10%" },
      { Header: "ROI_mean", accessor: "ROI_mean", width: "10%" },
      { Header: "daily_budget", accessor: "daily_budget", width: "10%" },
      // { Header: "EDIT", accessor: "edit", disableSortBy: true },
    ],
    rows: [],
  });

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedName, setSelectedName] = useState("All");
  const [selectedColumn, setSelectedColumn] = useState("Spend");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const names = ["All", "Vlad", "Alex", "Artem", "Anton", "Andrei", "Maryna"];
  const columns = [
    "Spend",
    "Revenue_a",
    "Revenue_f",
    "ROI",
    "ROI_f",
    "ROI_latest",
    "ROI_mean",
    "daily_budget",
  ];

  useEffect(() => {
    const emailsNames = {
      "Artemyusi@gmail.com": "Artem",
      "Vlad0986613794@gmail.com": "Vlad",
      "achudak23@gmail.com": "Anton",
      "nuzhnyias@gmail.com": "Alex",
      "xxxuko@gmail.com": "Anya",
      "Hembarskyi@gmail.com": "Michail",
      "andrew@salesbrush.com": "Andrew",
      "andreylipovskiy0@gmail.com": "Andrei",
      "mcerneva2@gmail.com": "Maryna",
    };
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/api/campaigns/"
        );
        let rows = response.data.map((rule) => ({
          update_time: rule.update_time,
          camp_id: rule.camp_id,
          campaign_name: rule.campaign_name,
          campaign_status: rule.campaign_status,
          stop_loss_1: `stop_loss_1 (${rule.stop_loss_1 === "ACTIVE" ? "ACTIVE" : "PAUSED"})`,
          rule_status: rule.rule_status,
          campaignToggle: (
            <FormControlLabel
              control={
                <Switch
                  checked={rule.campaign_status === "ACTIVE"}
                  onChange={() => handleCampaignToggle(rule.camp_id, rule.campaign_status)}
                />
              }
              label={rule.campaign_status === "ACTIVE" ? "Active" : "Paused"}
            />
          ),
          // ruleToggle: (
          //   <FormControlLabel
          //     control={
          //       <Switch
          //         checked={rule.stop_loss_1 === "ACTIVE"}
          //         onChange={() => handleRuleToggle(rule.camp_id, rule.stop_loss_1)}
          //       />
          //     }
          //     label=""
          //   />
          // ),
          Spend: rule.Spend,
          Revenue_a: rule.Revenue_a,
          Revenue_f: rule.Revenue_f,
          ROI: rule.ROI,
          ROI_f: rule.ROI_f,
          ROI_latest: rule.ROI_latest,
          ROI_mean: rule.ROI_mean,
          daily_budget: rule.daily_budget,
          edit: (
            <IconButton onClick={() => handleEditClick(rule)}>
              <EditIcon />
            </IconButton>
          ),
        }));

        if (userRole !== "admin") {
          const userName = emailsNames[userEmail];
          rows = rows.filter((row) => row.campaign_name.includes(userName));
        }

        setTableData((prevState) => ({ ...prevState, rows }));
        setFilteredData(rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filterData = () => {
      let filteredRows = tableData.rows;

      // Filter by Media Buyer Name
      if (selectedName !== "All") {
        filteredRows = filteredRows.filter((row) =>
          row.campaign_name.toLowerCase().includes(selectedName.toLowerCase())
        );
      }

      // Search filter
      if (search) {
        filteredRows = filteredRows.filter((row) =>
          Object.values(row).some((val) =>
            cleanValue(String(val)).toLowerCase().includes(search.toLowerCase())
          )
        );
      }

      // Min & Max Value filter
      if (minValue && maxValue) {
        filteredRows = filteredRows.filter((row) => {
          const value = parseFloat(cleanValue(row[selectedColumn]));
          return !isNaN(value) && value >= parseFloat(minValue) && value <= parseFloat(maxValue);
        });
      }

      setFilteredData(filteredRows);
    };

    filterData();
  }, [search, selectedName, selectedColumn, minValue, maxValue, tableData.rows]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleNameChange = (event) => {
    setSelectedName(event.target.value);
  };

  const cleanValue = (value) => {
    if (typeof value === "string") {
      return value.replace(/[%$]/g, "");
    }
    return value;
  };

  const handleColumnChange = (event) => {
    setSelectedColumn(event.target.value);
  };

  const handleMinValueChange = (event) => {
    setMinValue(event.target.value);
  };

  const handleMaxValueChange = (event) => {
    setMaxValue(event.target.value);
  };

  const handleCampaignToggle = useCallback(async (campaign_id = "", currentStatus = "PAUSED") => {
    const newStatus = currentStatus === "ACTIVE" ? "PAUSED" : "ACTIVE";

    // Update tableData state to reflect the new campaign status and toggle
    setTableData((prevState) => ({
      ...prevState,
      rows: prevState.rows.map((row) =>
        row.camp_id === campaign_id
          ? {
              ...row,
              campaign_status: newStatus,
              campaignToggle: (
                <FormControlLabel
                  control={
                    <Switch
                      checked={newStatus === "ACTIVE"}
                      onChange={() => handleCampaignToggle(campaign_id, newStatus)}
                    />
                  }
                  label={newStatus === "ACTIVE" ? "Active" : "Paused"}
                />
              ),
            }
          : row
      ),
    }));

    axios
      .post(
        "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/api/campaigns/change_campaign_status/",
        { campaign_id, current_status: currentStatus }
      )
      .then(() => {
        toast.success(`Campaign status for ID ${campaign_id} updated!`, {
          containerId: "containerA",
        });
      })
      .catch((error) => {
        console.error("Error toggling campaign status:", error);
        setTableData((prevState) => ({
          ...prevState,
          rows: prevState.rows.map((row) =>
            row.camp_id === campaign_id
              ? {
                  ...row,
                  campaign_status: currentStatus,
                  campaignToggle: (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={currentStatus === "ACTIVE"}
                          onChange={() => handleCampaignToggle(campaign_id, currentStatus)}
                        />
                      }
                      label={currentStatus === "ACTIVE" ? "ACTIVE" : "PAUSED"}
                    />
                  ),
                }
              : row
          ),
        }));
        toast.error("Error updating campaign status.", {
          containerId: "containerA",
        });
      });
  }, []);

  const handleRuleToggle = async (campaign_id, currentStatus) => {
    const newStatus = currentStatus === "ACTIVE" ? "PAUSED" : "ACTIVE";

    // Оптимистическое обновление состояния
    setTableData((prevState) => ({
      ...prevState,
      rows: prevState.rows.map((row) =>
        row.camp_id === campaign_id
          ? {
              ...row,
              stop_loss_1: `stop_loss_1 (${newStatus === "ACTIVE" ? "ACTIVE" : "PAUSED"})`,
              rule_status: newStatus,
              ruleToggle: (
                <FormControlLabel
                  key={campaign_id}
                  control={
                    <Switch
                      checked={newStatus === "ACTIVE"}
                      onChange={() => handleRuleToggle(campaign_id, newStatus)}
                    />
                  }
                  label=""
                />
              ),
            }
          : row
      ),
    }));

    axios
      .post(
        "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/api/campaigns/change_campaign_rule_status/",
        { campaign_id, current_status: currentStatus }
      )
      .then(() => {
        toast.success(`Rule status for campaign ID ${campaign_id} updated!`, {
          containerId: "containerB",
        });
      })
      .catch((error) => {
        console.error("Error toggling rule status:", error);
        setTableData((prevState) => ({
          ...prevState,
          rows: prevState.rows.map((row) =>
            row.camp_id === campaign_id
              ? {
                  ...row,
                  stop_loss_1: `stop_loss_1 (${currentStatus === "ACTIVE" ? "Active" : "Paused"})`,
                  rule_status: currentStatus,
                  ruleToggle: (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={currentStatus === "ACTIVE"}
                          onChange={() => handleRuleToggle(campaign_id, currentStatus)}
                        />
                      }
                      label=""
                    />
                  ),
                }
              : row
          ),
        }));
        toast.error("Error updating rule status.", {
          containerId: "containerB",
        });
      });
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);
  };

  const handleEditClick = (rule) => {
    setSelectedRow(rule);
    setOpenEditDialog(true);
  };

  const handleSave = async (activationDateTime) => {
    if (!selectedRow) return;

    const updatedData = {
      camp_id: selectedRow.camp_id,
      campaign_name: selectedRow.campaign_name,
      activate_time: activationDateTime,
      campaign_status: selectedRow.campaign_status,
      user_email: userEmail,
      Spend: selectedRow.Spend,
      Revenue_a: selectedRow.Revenue_a,
      ROI: selectedRow.ROI,
      Profit: selectedRow.Profit,
      Revenue_f: selectedRow.Revenue_f,
      ROI_f: selectedRow.ROI_f,
      Profit_f: selectedRow.Profit_f,
    };

    try {
      await axios.post(
        "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/schedule_activation_time/",
        updatedData
      );
      toast.success("Activation time scheduled successfully!");
    } catch (error) {
      if (error.response && error.response.data) {
        console.error("Error updating data:", error.response.data);
      } else {
        console.error("Error:", error.message);
      }
      toast.error("Failed to schedule activation time!");
    }

    handleEditDialogClose();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {/* Card for Min Value, Max Value, and Column Selection */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ width: "100%", padding: 2 }}>
              <MDBox py={3} px={3}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Min & Max Value and Column Selection
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Min Value"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={minValue}
                      onChange={handleMinValueChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Max Value"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={maxValue}
                      onChange={handleMaxValueChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel>Column</InputLabel>
                      <Select
                        value={selectedColumn}
                        onChange={handleColumnChange}
                        label="Column"
                        sx={{
                          "& .MuiSelect-select": {
                            height: 40,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 8,
                          },
                        }}
                      >
                        {columns.map((column) => (
                          <MenuItem key={column} value={column}>
                            {column}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </MDBox>
            </Paper>
          </Grid>

          {/* Card for MediaBuyer Name Filter and Search */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ width: "100%", padding: 2 }}>
              <MDBox py={3} px={3}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Media Buyer Name & Search
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel>Media Buyer Name</InputLabel>
                      <Select
                        value={selectedName}
                        onChange={handleNameChange}
                        label="Media Buyer Name"
                        sx={{
                          "& .MuiSelect-select": {
                            height: 40,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 8,
                          },
                        }}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Search"
                      variant="outlined"
                      fullWidth
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Paper>
          </Grid>

          {/* Data Table */}
          <Grid item xs={12}>
            <DataTable table={{ ...tableData, rows: filteredData }} entriesPerPage={false} />
          </Grid>
          {/* EditDialog */}
          <EditDialog
            openEditDialog={openEditDialog}
            handleEditDialogClose={() => setOpenEditDialog(false)}
            selectedRow={selectedRow}
            handleSave={handleSave}
          />
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CampaignsALL;
