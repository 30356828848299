import React, { useEffect, useState } from "react";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "./styles.css";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomDropdown from "../dropdown_component";
import OptionsCheckboxList from "./types_options";
import FormField from "../../pages/account/components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import CustomNumericInputField from "../input_number_field";
import CustomInputFieldMultiline from "../input_field_multiline";

function ScaleCampaign() {
  const API_URL = "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app";
  const API_URL_UPDATE = "https://fb-campaign-creator-only-campaigns-tmgx45bhsa-uc.a.run.app";
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const [selectedCampaignName, setSelectedCampaignName] = useState("");
  const [CampaignNamesAll, setCampaignNamesAll] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("PAUSED");
  const statusesAll = ["ACTIVE", "PAUSED"];

  const [selectedBidOptions, setSelectedBidOptions] = useState([]);
  const [selectedRate, setSelectedRate] = useState("RPC current");
  const [inputRate, setInputRate] = useState("1");
  const [inputPercentage, setInputPercentage] = useState("10");
  const ratesAll = ["RPC_mean_7_days", "RPC current", "CPA_mean_7_days", "CPA current", "CUSTOM"];
  // const bidOptions = [
  //   `+${inputPercentage}% to selected rate (${selectedRate})`,
  //   `-${inputPercentage}% from selected rate (${selectedRate})`,
  //   `Custom Bid: ${inputRate}$`,
  // ];
  const bidOptions = [`Custom Bid`, `+% to selected rate`, `-% from selected rate`];

  const platformOptions = ["FB + INSTAGRAM", "FB", "INSTAGRAM", "ALL"];
  const [selectedPlatformOptions, setSelectedPlatformOptions] = useState([]);

  const osOptions = ["IOS + ANDROID", "IOS", "ANDROID", "ALL"];
  const [selectedOsOptions, setSelectedOsOptions] = useState([]);

  const [selectedType, setSelectedType] = useState("");
  const typesAll = ["ABO | COST_CAP", "ABO | BID_CAP", "ABO Highest Delivery"];

  const [selectedAdsetSetting, setSelectedAdsetSetting] = useState("");
  const adsetSettingsAll = [
    "Create All Ads in One Ad Set",
    "Copy Active Ad Sets with Active Ads from Parent Campaign",
    "1 Ad in 1 Adset",
  ];

  const [selectedPage, setSelectedPage] = useState("");
  const [pagesAll, setPagesAll] = useState([]);

  const [selectedAccount, setSelectedAccount] = useState("");
  const [accountsAll, setAccountsAll] = useState([]);

  const [inputBudget, setInputBudget] = useState("5");
  const [inputLinkForFB, setInputLinkForFB] = useState("");

  const Tips = [
    "To create new campaigns, you must select a parent campaign from which all ad sets will be copied.",
    "You need to select the account and fb_page on which new campaigns will be created.",
    "Choose what type of campaign to create by selecting the desired one from the list.",
    "The campaign stage for created campaigns will be set to 'L1'.",
  ];
  const Tips2 = [
    "You can choose the status of the newly created ad sets (active or paused).",
    "Only ACTIVE Ad Sets and Ads can be copied.",
    "When selecting multiple options, ad sets will be created for each of these options.",
    "The maximum number of ad sets is 50.",
    "The maximum number of ads in one ad set is 50.",
  ];
  const Tips3 = [
    "DO NOT CHANGE the values in these fields if you are creating a campaign without bid/cost.",
    "When selecting multiple options, ad sets will be created for each of these options.",
  ];

  const renderTips = (tipsList) => {
    return tipsList.map((item, key) => {
      const itemKey = `element-${key}`;

      return (
        <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
            {item}
          </MDTypography>
        </MDBox>
      );
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const accountsResponse = await fetch(`${API_URL}/get_accounts_all/`);
        const accountsData = await accountsResponse.json();
        setAccountsAll(accountsData);

        const pageResponse = await fetch(`${API_URL}/get_pages_all/`);
        const pagesData = await pageResponse.json();
        setPagesAll(pagesData);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${API_URL}/get_campaigns_names_adsets_types/`);
        const data = await response.json();

        const emailsNames = {
          "Artemyusi@gmail.com": "Artem",
          "Vlad0986613794@gmail.com": "Vlad",
          "achudak23@gmail.com": "Anton",
          "nuzhnyias@gmail.com": "Alex",
          "xxxuko@gmail.com": "Anya",
          "Hembarskyi@gmail.com": "Michail",
          "andrew@salesbrush.com": "Andrew",
          "andreylipovskiy0@gmail.com": "Andrey",
          "mcerneva2@gmail.com": "Maryna",
        };

        let filteredCampaignNames;

        if (userRole === "admin") {
          // Extract unique values from the data for admin role
          filteredCampaignNames = [...new Set(data.map((item) => item.campaign_id_name))];
        } else {
          const userName = emailsNames[userEmail];
          // Filter campaign names based on the user's name
          filteredCampaignNames = [
            ...new Set(
              data
                .map((item) => item.campaign_id_name)
                .filter((campaignName) => campaignName.includes(userName))
            ),
          ];
        }

        // Update state with filtered values
        setCampaignNamesAll(filteredCampaignNames);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleScaleCampaign = () => {
    if (
      !selectedCampaignName ||
      !selectedAccount ||
      !selectedPage ||
      !selectedType ||
      !selectedAdsetSetting ||
      !selectedStatus ||
      !inputBudget ||
      !selectedRate ||
      !inputRate ||
      !inputPercentage ||
      selectedPlatformOptions.length === 0 ||
      selectedOsOptions.length === 0 ||
      selectedBidOptions.length === 0
    ) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    fetch(`${API_URL_UPDATE}/campaign_scale/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        properties: {
          campaign_name_data: selectedCampaignName,
          ad_account: selectedAccount,
          fb_page: selectedPage,
          campaign_type: selectedType,
          adset_count_option: selectedAdsetSetting,
          adset_status: selectedStatus,
          budget: inputBudget,
          platform_options: selectedPlatformOptions,
          os_options: selectedOsOptions,
          bid_start_rate: selectedRate,
          custom_rate_value: inputRate,
          custom_percentage: inputPercentage,
          bid_options: selectedBidOptions,
          fb__link_for_fb: inputLinkForFB,
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("Campaign successfully updated!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCheckCampaign = () => {
    if (
      !selectedCampaignName ||
      !selectedAccount ||
      !selectedPage ||
      !selectedType ||
      !selectedAdsetSetting ||
      !selectedStatus ||
      !inputBudget ||
      !selectedRate ||
      !inputRate ||
      !inputPercentage ||
      selectedPlatformOptions.length === 0 ||
      selectedOsOptions.length === 0 ||
      selectedBidOptions.length === 0
    ) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    fetch(`${API_URL_UPDATE}/campaign_check/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        properties: {
          campaign_name_data: selectedCampaignName,
          ad_account: selectedAccount,
          fb_page: selectedPage,
          campaign_type: selectedType,
          adset_count_option: selectedAdsetSetting,
          adset_status: selectedStatus,
          budget: inputBudget,
          platform_options: selectedPlatformOptions,
          os_options: selectedOsOptions,
          bid_start_rate: selectedRate,
          custom_rate_value: inputRate,
          custom_percentage: inputPercentage,
          bid_options: selectedBidOptions,
          fb__link_for_fb: inputLinkForFB,
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("Campaign successfully Checked!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleCheckCampaign}>
            Check 👀
          </button>
          <button className="button-list-button" onClick={handleScaleCampaign}>
            Scale campaign💨
          </button>
        </div>
      </div>
    );
  };

  const ResponseMessage = () => {
    return (
      <div className="response-message-container">
        {isLoading ? (
          <div className="loading-spinner">
            <CircularProgress color="success" />
          </div>
        ) : (
          <Card className="response-message" sx={{ overflow: "auto" }}>
            <pre>{responseMessage}</pre>
          </Card>
        )}
      </div>
    );
  };

  const handleOptionsBidChange = (options) => {
    setSelectedBidOptions([]);
    setSelectedBidOptions(options);
  };

  const handleOptionsPlatformChange = (options) => {
    setSelectedPlatformOptions(options);
  };

  const handleOptionsOsChange = (options) => {
    setSelectedOsOptions(options);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={3}>
                <MDTypography variant="h5">⚙️ Parent & Child campaigns Setup</MDTypography>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                  mt={3}
                >
                  <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                    {renderTips(Tips)}
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <CustomDropdown
                          selectedCategory={selectedCampaignName}
                          setSelectedCategory={setSelectedCampaignName}
                          categories={CampaignNamesAll}
                          category_name={"Campaign ID | Name"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <CustomDropdown
                          selectedCategory={selectedAccount}
                          setSelectedCategory={setSelectedAccount}
                          categories={accountsAll}
                          category_name={"FB - AD account"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4.5}>
                        <CustomDropdown
                          selectedCategory={selectedPage}
                          setSelectedCategory={setSelectedPage}
                          categories={pagesAll}
                          category_name={"FB - page"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={2.5}>
                        <CustomDropdown
                          selectedCategory={selectedType}
                          setSelectedCategory={setSelectedType}
                          categories={typesAll}
                          category_name={"Select campaign type"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDTypography variant="h6" color="text">
                          Fb__link_for_fb
                        </MDTypography>
                        <CustomInputFieldMultiline
                          inputValue={inputLinkForFB}
                          setInputValue={setInputLinkForFB}
                          label={"Ignore this field if you want to use the parent fb_link value."}
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </MDBox>
              <MDBox p={2} mb={3}>
                <MDTypography variant="h5">📝 Ad Set Configuration</MDTypography>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                  mt={3}
                >
                  <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                    {renderTips(Tips2)}
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <CustomDropdown
                          selectedCategory={selectedAdsetSetting}
                          setSelectedCategory={setSelectedAdsetSetting}
                          categories={adsetSettingsAll}
                          category_name={"Ad Set option"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <CustomDropdown
                          selectedCategory={selectedStatus}
                          setSelectedCategory={setSelectedStatus}
                          categories={statusesAll}
                          category_name={"Ad Set status after creation"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <CustomNumericInputField
                          inputValue={inputBudget}
                          setInputValue={setInputBudget}
                          label={"Daily adset budget (in $)"}
                          allowFloat={true}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDTypography variant="h6" color="text">
                          🧩 Select Publisher Platforms (default: FB + INSTAGRAM)
                        </MDTypography>
                        <OptionsCheckboxList
                          onOptionsChange={handleOptionsPlatformChange}
                          typesAll={platformOptions}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDTypography variant="h6" color="text">
                          🧩 Select Os (default: IOS + ANDROID)
                        </MDTypography>
                        <OptionsCheckboxList
                          onOptionsChange={handleOptionsOsChange}
                          typesAll={osOptions}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </MDBox>
              {selectedType !== "ABO Highest Delivery" && (
                <>
                  <MDBox p={2} mb={3}>
                    <MDTypography variant="h5">
                      💰 Bid Settings (Skip this section for campaigns without bid or cost controls)
                    </MDTypography>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      flexWrap="wrap"
                      mt={3}
                    >
                      <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                        {renderTips(Tips3)}
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox p={2}>
                    <Grid container spacing={2} direction="column" alignItems="stretch">
                      <MDBox p={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={9}>
                            <CustomDropdown
                              selectedCategory={selectedRate}
                              setSelectedCategory={setSelectedRate}
                              categories={ratesAll}
                              category_name={"Calculate bid|cost amount from"}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            {selectedRate === "CUSTOM" && (
                              <CustomNumericInputField
                                inputValue={inputRate}
                                setInputValue={setInputRate}
                                label={"Custom rate value (in $)"}
                                allowFloat={true}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <MDTypography variant="h6" color="text">
                              🧩 Select Bid options
                            </MDTypography>
                            <Grid item xs={12} md={3} mt={3} ml={4}>
                              <CustomNumericInputField
                                inputValue={inputPercentage}
                                setInputValue={setInputPercentage}
                                label={"Custom Bid percentage (in %)"}
                              />
                            </Grid>
                            <OptionsCheckboxList
                              onOptionsChange={handleOptionsBidChange}
                              typesAll={bidOptions}
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                  </MDBox>
                </>
              )}
              <MDBox pt={2} px={2}>
                <ButtonList />
                <MDBox p={2} mb={3} display="flex" alignItems="center">
                  <img
                    src="https://www.svgrepo.com/show/404652/arrow-direction-right-pointer-next.svg"
                    alt="Red Arrow"
                    style={{ width: "50px", height: "50px" }}
                  />
                  <MDTypography variant="h5">&nbsp;&nbsp;Check limits before scale</MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ScaleCampaign;
